
import Kirbylink from './kirbylink.vue'
 
import Kirbyimage from './kirbyimage.vue'
  import Swiper from 'swiper'; 

export default
{
    name: "iconslides",
    data(){
        return {
            slides: [],
            chunks: []
        }
    },
    methods:{
        prev() {
            document.querySelector('.swiper-text').swiper.slidePrev();
        },
        next() {
            document.querySelector('.swiper-text').swiper.slideNext();
        }
    },
    mounted() {


        var x = window.matchMedia("(max-width: 1800px)")

        let chunkSize = 8;

        if (x.matches) {        
            chunkSize = 6;    
        }

        for (let i = 0; i < this.content.logos.length; i += chunkSize) {
            const chunk = this.content.logos.slice(i, i + chunkSize);
            this.chunks.push(chunk);
        }

        setTimeout(function(){
            
            new Swiper(".swiper-text", {
                hashNavigation: {
                    watchState: true,
                },
                loop: true,
                slideToClickedSlide: true,
                slidesPerView: 1,
            });
        }, 1000);

    },
    props: {
        content: Object
    },
    components: { Kirbylink, Kirbyimage }
}

